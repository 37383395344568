.timeline {
  box-sizing: border-box;
  position: relative;
}

.timeline *, .timeline :after, .timeline :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.timeline:not(.timeline--horizontal):before {
  content: "";
  z-index: 1;
  background-color: #ddd;
  width: 4px;
  margin-left: -2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline__wrap {
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.timeline__item {
  z-index: 2;
  width: 50%;
  padding: .625rem 2.5rem .625rem 0;
  font-size: 1rem;
  position: relative;
}

.timeline__item:after {
  content: "";
  z-index: 1;
  background-color: #fff;
  border: 4px solid #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.timeline__item.animated {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.timeline__item.fadeIn {
  animation-name: fadeIn;
}

.timeline__item--left {
  left: 0;
}

.timeline__item--right {
  padding: .625rem 0 .625rem 2.5rem;
  left: 50%;
}

.timeline__item--right:after {
  left: -10px;
}

.timeline__item--right .timeline__content:before {
  border: 10px solid #0000;
  border-left: none;
  border-right: 12px solid #ccc;
  left: -12px;
}

.timeline__item--right .timeline__content:after {
  border: 9px solid #0000;
  border-left: none;
  border-right: 11px solid #fff;
  left: -10px;
}

.timeline__content {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1.25rem;
  display: block;
  position: relative;
}

.timeline__content:after, .timeline__content:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline__content:before {
  z-index: 1;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 12px solid #ccc;
  right: -12px;
}

.timeline__content:after {
  z-index: 2;
  border-top: 9px solid #0000;
  border-bottom: 9px solid #0000;
  border-left: 11px solid #fff;
  right: -10px;
}

.timeline__content h2 {
  margin: 0 0 .625rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.timeline__content p {
  margin-bottom: 10px;
  font-size: .9375rem;
  line-height: 1.5;
}

.timeline--horizontal {
  white-space: nowrap;
  padding: 0 3.125rem;
  font-size: 0;
  overflow: hidden;
}

.timeline--horizontal .timeline-divider {
  z-index: 1;
  background-color: #ddd;
  height: 4px;
  display: block;
  position: absolute;
  left: 40px;
  right: 40px;
  transform: translateY(-50%);
}

.timeline--horizontal .timeline__items {
  -o-transition: all .8s;
  will-change: transform;
  transition: all .8s;
}

.timeline--horizontal .timeline__item {
  -o-transition: none;
  vertical-align: top;
  white-space: normal;
  padding: 0 0 2.5rem;
  transition: none;
  display: inline-block;
  position: relative;
  left: 0;
}

.timeline--horizontal .timeline__item:after {
  top: 100%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.timeline--horizontal .timeline__item .timeline__item__inner {
  width: 100%;
  height: 100%;
  display: table;
}

.timeline--horizontal .timeline__item .timeline__content__wrap {
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  display: table-cell;
}

.timeline--horizontal .timeline__item .timeline__content:before {
  border-top: 12px solid #ccc;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  top: 100%;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.timeline--horizontal .timeline__item .timeline__content:after {
  border-top: 10px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  top: 100%;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.timeline--horizontal .timeline__item--bottom {
  padding: 2.5rem 0 0;
}

.timeline--horizontal .timeline__item--bottom:after {
  top: 0;
}

.timeline--horizontal .timeline__item--bottom .timeline__content__wrap {
  vertical-align: top;
}

.timeline--horizontal .timeline__item--bottom .timeline__content:before {
  border: 12px solid #0000;
  border-top: none;
  border-bottom-color: #ccc;
  top: auto;
  bottom: 100%;
}

.timeline--horizontal .timeline__item--bottom .timeline__content:after {
  border: 10px solid #0000;
  border-top: none;
  border-bottom-color: #fff;
  top: auto;
  bottom: 100%;
}

.timeline-nav-button {
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 10;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50px;
  outline: 0;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-nav-button:disabled {
  opacity: .5;
  pointer-events: none;
}

.timeline-nav-button:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  width: 8px;
  height: 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.timeline-nav-button--prev {
  left: 0;
}

.timeline-nav-button--prev:before {
  background-image: url("arrow-left.95b1ec1a.svg");
}

.timeline-nav-button--next {
  right: 0;
}

.timeline-nav-button--next:before {
  background-image: url("arrow-right.6a4fee58.svg");
}

.timeline--mobile {
  padding: 0;
}

.timeline--mobile:before {
  margin: 0 !important;
  left: 10px !important;
}

.timeline--mobile .timeline__item {
  width: 100%;
  padding-left: 40px;
  padding-right: 0;
  left: 0;
}

.timeline--mobile .timeline__item:after {
  margin: 0;
  left: 2px;
}

.timeline--mobile .timeline__item .timeline__content:before {
  border: 12px solid #0000;
  border-left: none;
  border-right-color: #ccc;
  left: -12px;
}

.timeline--mobile .timeline__item .timeline__content:after {
  border: 10px solid #0000;
  border-left: none;
  border-right-color: #fff;
  left: -10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 70px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes liftUp {
  0% {
    top: 0;
  }

  100% {
    top: -15px;
  }
}
/*# sourceMappingURL=index.8af4fa13.css.map */
